import { useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import styles from './headerFeedback.module.css';
import wrappers from "components/_styled/wrappers.module.css"

import { notificationDismiss } from "_slices/notifications";

type MessageProps = {
  noteId: string;
  index: number;
};

const Message = ({ noteId, index }: MessageProps) => {
  const dispatch = useDispatch();
  const { state, messages } = useSelector((reduxState: RootStateOrAny) => reduxState.notifications.active[noteId]);
  const [expired, setExpired] = useState(false);
  

  useEffect(() => {
    if (index > 4) setExpired(true);
      setTimeout(clearNotfication, 5000);
  }, [index]);

  function clearNotfication() {
    dispatch(notificationDismiss(noteId))
  }

  return (
    <div className={`${styles.Notification} ${state === "success" ? styles.notifSuccess : state === "error" ? styles.notifError : styles.notifDefault}`}
      style={{padding: `0.5rem 0.75rem 0.5rem ${state === "success"? "2rem" : "0.75rem"}`}}
    >
      {state === "success" && <img src="/icons/check-green.svg"/>}
      {messages.map((msg, i) => (
        <p key={i}>{msg}</p>
      ))}
    </div>
  );
};

const Feedback = () => {
  const notifications = useSelector((state: RootStateOrAny) => state.notifications.location.header);

  return (
    <div className={`${styles.Wrapper} ${wrappers.SmoothWrapper}`}>
      <div className={styles.Feed}>
        {notifications.map((noteId, index) => (
          <Message key={noteId} noteId={noteId} index={index} />
        ))}
      </div>
    </div>
  );
};

export default Feedback;
