'use client'

import useMount from "react-use/lib/useMount";
import appPackage from "package.json";
import { useSearchParams, useRouter, usePathname } from "next/navigation";
import dynamic from "next/dynamic";
import type { AppProps } from "next/app";
// import { Partytown } from "@builder.io/partytown/react";
import { useDispatch } from "react-redux";
import Header from "components/Header";
import Footer from "components/Footer";
import Page from "components/Page";
import { Provider } from "../context/context";
import './global.css';


const AuthModal = dynamic(() => import("components/Auth/CombiModal"), {
  ssr: false,
});


import initGA from "lib/ga/init";
import { wrapper } from "lib/store";
import { modalSet, nextModalSet } from "_slices/view";
import { setInviteCode, setResetCode } from "_slices/auth";
import AuthService from "_api/AuthService";

import Script from "next/script";

const CookieMessage = dynamic(() => import("components/CookieMessage"), {
  ssr: false,
});


function MyApp({ Component, pageProps = {}, children, hasToken }: AppProps) {
  const router = useSearchParams();
  const routerMeth = useRouter();
  const pathname = usePathname();
  const reduxDispatch = useDispatch();
  useMount(() => {
    initGA();

    //const searchParams = urlParamsToObject(router);

    // Check for an modal parameters and pass them to the state
    if (router.get('modal')) {
      let [type, view] = router.get('modal').split("-");
      reduxDispatch(
        modalSet({
          visible: true,
          type,
          view,
          isLoggedIn: true,
          router: routerMeth
        })
      );
    }
    if (router.get('nextModal')) {
      let [type, view] = router.get('nextModal').split("-");
      reduxDispatch(
        nextModalSet({
          visible: true,
          type,
          view,
          isLoggedIn: hasToken,
          router: routerMeth
        })
      );
    }

    if (router.get('inviteCode')) reduxDispatch(setInviteCode({ inviteCode: router.get('inviteCode'), router: routerMeth }));
    if (router.get('resetCode')) reduxDispatch(setResetCode({ resetCode: router.get('resetCode'), router: routerMeth }));
  });

  return (
    <main>
      {process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID && (
        <>
          <Script id="datadog-rum">
            {`(function(h,o,u,n,d) {

   h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
   d=o.createElement(u);d.async=1;d.src=n
   n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
})(window,document,'script','https://www.datadoghq-browser-agent.com/eu1/v5/datadog-rum.js','DD_RUM')
window.DD_RUM.onReady(function() {
  window.DD_RUM.init({
    clientToken: '${process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN}',
    applicationId: '${process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID}',
    site: 'datadoghq.eu',
    service: 'cr-vercel',
    env: '${process.env.NEXT_PUBLIC_DATADOG_ENV || "unknown"}',
    //  version: '1.0.0',
    version: '${appPackage.version}',
    sessionSampleRate: 20,
    sessionReplaySampleRate: 0,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    compressIntakeRequest: true,

  })
});`}
          </Script>
        </>

      )}
      <Provider loggedIn={hasToken}>
        <Header isApplicationWrapper={pageProps.applicationWrapper} />
        <Page transparent={pathname === '/'}>
          {children}
        </Page>

        <Footer isApplicationWrapper={pageProps.applicationWrapper} showPopular={pageProps.showPopular} />
        <CookieMessage />
        <AuthModal />
        <AuthService />
      </Provider>


    </main>
  );
}

export default wrapper.withRedux(MyApp);
