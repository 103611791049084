import styles from './recaptchaSignage.module.css'

const Inline = () => (
  <div className={styles.InlineWrapper}>
    <span>
      protected by <strong>reCAPTCHA</strong>
    </span>
    <a href="https://www.google.com/intl/en/policies/privacy/" target="_blank">
      Privacy
    </a>
    <span> - </span>
    <a href="https://www.google.com/intl/en/policies/terms/" target="_blank">
      Terms
    </a>
  </div>
);

export default Inline;
