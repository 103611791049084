import Link from "next/link";
import { popularLinks, popularLinksHomePage } from "config/popularLinks.config";
import styles from './popularSearches.module.css'


const PopularSearches = ({ homePage }) => {
  const popularLinksList = homePage ? popularLinksHomePage : popularLinks;
  return (
    <section className={styles.Popular}>
      {/* Popular */}
      <h2>Popular Searches</h2>
      <p>
        Check out our popular searches which provide you with all the key area information needed to help you decide on
        the destination for your next retail property
      </p>
      <ul>
        {popularLinksList.map(({ href, label }) => (
          <li key={href}>
            <Link href={href} passHref prefetch={false} legacyBehavior>
              <a>{label}</a>
            </Link>
          </li>
        ))}
      </ul>
    </section>
  )
};

export default PopularSearches;
