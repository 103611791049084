
export const popularLinks = [
    { href: '/shops-to-rent/glasgow-town', label: 'Shops to Rent Glasgow'},
    { href: '/shops-to-rent/liverpool', label: 'Liverpool retail units'},
    { href: '/shops-to-rent/birmingham', label: 'Birmingham retail units'},
    { href: '/shops-to-rent/london', label: 'London retail units'},
    { href: '/shops-to-rent/aberdeen', label: 'Aberdeen retail units'},
    { href: '/shops-to-rent/manchester', label: 'Manchester retail units'},
    { href: '/shops-to-rent/bristol', label: 'Bristol retail units'},
    { href: '/shops-to-rent/milton-keynes', label: 'Milton Keynes retail units'},
    { href: '/shops-to-rent/leeds', label: 'Leeds retail units'},
    { href: '/shops-to-rent/nottingham', label: 'Nottingham retail units'},
]

export const popularLinksHomePage = [
    { href: '/shops-to-rent/glasgow-town', label: 'Shops to Rent Glasgow'},
    { href: '/shops-to-rent/liverpool', label: 'Liverpool retail units'},
    { href: '/shops-to-rent/birmingham', label: 'Birmingham retail units'},
    { href: '/shops-to-rent/aberdeen', label: 'Aberdeen retail units'},
    { href: '/shops-to-rent/london', label: 'London retail property to rent'},
    { href: '/shops-to-rent/manchester', label: 'Manchester retail units'},
    { href: '/shops-to-rent/bristol', label: 'Bristol retail units'},
    { href: '/shops-to-rent/leeds', label: 'Leeds retail units'},
    { href: '/shops-to-rent/nottingham', label: 'Nottingham retail units'},
    { href: '/shops-to-rent', label: 'Empty shop for rent near me'},
];



