import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getAuthToken } from "_slices/auth";
import axios from "./axiosPrivate";

const AuthService = () => {
  let token = useSelector(getAuthToken);
  let [interceptor, setInterceptor] = useState(null);

  useEffect(() => {
    if (token) {
      let inter = axios.interceptors.request.use(function (request) {
        request.headers.Authorization = `Bearer ${token}`;
        request.headers.accept = `application/json`;

        return request;
      });

      setInterceptor(inter);
    } else {
      if (interceptor) {
        axios.interceptors.request.eject(interceptor);
      }
    }

    return () => {
      if (interceptor) {
        axios.interceptors.request.eject(interceptor);
      }
    };
  }, [token]);

  return null;
};

export default AuthService;
