import { PasswordCondition } from "_interfaces/auth";

export const sectors = ["RET"];

export const categories = ["SC", "LE", "HS", "RW"];

export const featuredSchemes = {
  LE: ['2972', '3040', '5866', '2534'],
  RW: ['3599', '3548', '7872', '2475'],
  SC: ['3618', '3794', '3624', '5108'],
  ALL: ['3980', '3286', '2117', '2341'],
  HS: ['4804', '6500', '5108', '3794']
}; 

// export const paramsOrder = [
//   "page",
//   "pageSize",
//   // 'keyword',
//   // 'category',
// ];

export const search = {
  mapDefaults: {
    center: {
      lat: 52.9051751,
      lng: -2.8232,
    },
    zoom: 6,
    maxZoom: 18,
  },
  defaultRadii: { def: 2, max: 15 },
  pagesToShow: 5,
  allowedParams: [
    // "page",
    // "perPage",
  ],
  typesOrder: ["PROPERTY", "SCHEME", "POPUP"],
  types: {
    PROPERTY: {
      rank: 0,
      criteria: ["LOCATION"],
      filters: [
        "keyword",
        "categories",
        "availability",
        "rent",
        "size",
        "use_class"
        // "distance"
      ],
      allowedParams: [],
      pageSize: 25,
      pageSizes: [25, 50, 100],
    },
    SCHEME: {
      rank: 1,
      criteria: ["LOCATION", "NAME"],
      filters: [
        "keyword",
        "categories",
        "size",
        // "distance"
      ],
      allowedParams: [],
      pageSize: 25,
      pageSizes: [25, 50, 100],
    },
    POPUP: {
      rank: 2,
      criteria: ["LOCATION"],
      filters: [
        "price",
        "space",
        // "duration",
      ],
      allowedParams: [],
      pageSize: 50,
      pageSizes: [25, 50, 100],
    },
  },

  criteria: {
    NAME: {
      filtersBlacklist: [],
    },
    LOCATION: {
      filtersBlacklist: [],
    },
    NEW: {
      filtersBlacklist: [],
    },
    FEATURED: {
      filtersBlacklist: [],
    },
    HOMEPAGE: {
      filtersBlacklist: [],
    },
    BID: {
      filtersBlacklist: [],
    },
    DESTINATION: {
      filtersBlacklist: [],
    },
    COMPANY: {
      filtersBlacklist: ["categories"],
    },
    PORTFOLIO: {
      filtersBlacklist: [],
    },
  },

  filtering: {
    keyword: {
      type: "text",
      initial: "",
      compareTo: "keywords",
      labels: {
        main: "Filter results by keyword",
        placeholder: "Filter by keyword...",
        logging: "Keyword",
        tab: "Keyword",
      },
    },
    categories: {
      type: "check-array",
      options: ["SC", "LE", "HS", "RW"],
      initial: ["SC", "LE", "HS", "RW"],
      compareTo: "category",
      labels: {
        main: "Select which categories to show",
        logging: "Categories",
        tab: "",
      },
    },
    use_class: {
      type: "check-array",
      options: ["B1", "B8", "C1", "C2", "C3", "E", "F1", "S", "F2"],
      initial: ["B1", "B8", "C1", "C2", "C3", "E", "F1", "S",  "F2"],
      compareTo: "use_class",
      labels: {
        main: "Select which use classes to show",
        logging: "Use classes",
        tab: "",
      },
    },
    sponsorship: {
      type: "sponsorship",
      options: ["sponsored", "unsponsored"],
      initial: ["sponsored", "unsponsored"],
      compareTo: "sponsored",
    },
    availability: {
      type: "select",
      options: ["All", "To Let", "Under Offer", "For sale/May Let"],
      initial: "all",
      useDefs: "AVAILABILITES_MAP",
      compareTo: "availability",
      labels: {
        main: "Select the availability you'd like to filter your results by",
        logging: "Availability",
        tab: "Availability",
      },
    },
    rent: {
      type: "slider",
      step: 100,
      compareTo: "rent",
      editable: {
        min: true,
        max: true,
        null: true,
      },
      labels: {
        main: "Filter the results by price",
        null: "Show results without price information",
        logging: "Rent",
        unit: "pa",
        tab: "Price",
      },
    },
    price: {
      type: "slider",
      step: 100,
      compareTo: "rent_monthly",
      editable: {
        min: true,
        max: true,
      },
      labels: {
        main: "Filter the results by price",
        logging: "Rent",
        unit: "pa",
        tab: "Price",
      },
    },
    size: {
      type: "slider",
      step: 100,
      compareTo: "size",
      editable: {
        min: true,
        max: true,
        null: true,
      },
      labels: {
        main: "Filter the results by size",
        null: "Show results without size information",
        logging: "Size",
        unit: "ft",
        tab: "Size",
      },
    },
    space: {
      type: "slider",
      step: 100,
      compareTo: "size_ft",
      editable: {
        min: true,
        max: true,
      },
      labels: {
        main: "Filter the results by size",
        logging: "Size",
        unit: "ft",
        tab: "Size",
      },
    },
    distance: {
      type: "slider",
      step: 0.1,
      compareTo: "distance",
      editable: {
        min: true,
        max: true,
        null: false,
      },
      labels: {
        main: "Filter the results by distance",
        null: "Show results without distance information",
        logging: "Distance",
        unit: "km",
        tab: "Distance",
      },
    },
  },
};

export const theme = {
  colours: {
    gradLeft: [232, 51, 107],
    gradRight: [235, 94, 64],
    primary: [236, 116, 116],
    header: [20, 27, 32],
  },
};

export const portfolioLogosToShrink = ["MandG", "NuveenRealEstate", "brookfield-properties"];

export const comparisons = {
  structureValidSince: 1625835850000,
};

// All values should be in seconds
export const revalidate = {
  A: 10 * 60, // 10 mins
  B: 20 * 60, // 20 mins
  C: 30 * 60, // 30 mins
  D: 60 * 60, // 1 hour
  E: 2 * 60 * 60, // 2 hours
  F: 24 * 60 * 60, // 24 hours
};

// We use this to set conditions for showing modals
export const modals = {
  contactRep: {
    conditions: {
      loggedIn: true,
    },
    views: {},
  },
  contactBrand: {
    conditions: {
      loggedIn: true,
      trust: 60,
    },
    views: {},
  },
  contactProperty: {
    conditions: {
      loggedIn: true, 
    },
    views: {},
  },
  upgradeProperty: {
    conditions: {
      loggedIn: true,
    },
    views: {},
  },
  contactAgentProperty: {
    conditions: {
      loggedIn: true,
    },
    views: {},
  },
  localAgentsContact: {
    conditions: {
      loggedIn: true
    },
    views: {}
  }
};

export const passwordConditions: PasswordCondition[] = [
  {
    id: "length",
    regex: /.{8,}/gm,
    label: "At least 8 characters long",
  },
  {
    id: "upper",
    regex: /[A-Z]/gm,
    label: "Contain at least 1 uppercase character",
  },
  {
    id: "lower",
    regex: /[a-z]/gm,
    label: "Contain at least 1 lowercase character",
  },
  {
    id: "number",
    regex: /\d/gm,
    label: "Contain at least 1 numeric character",
  },
  {
    id: "spaces",
    regex: /^\S*$/gm,
    label: "Contain no spaces",
  },
  {
    id: "special",
    regex: /[!@#$%^&\*_-]/gm,
    label: "Contain at least 1 of the following characters: !@#$%^&*_-",
  },
];

export const _APP = {
  sectors,
  categories,
  search,
  theme,
  portfolioLogosToShrink,
  comparisons,
  revalidate,
  modals,
  passwordConditions,
};

export default _APP;
