import { useState, useRef, useMemo, useEffect } from "react";
import styles from './StickyContainer.module.css';

type DefaultValues = {
  height: number;
};

type Props = {
  offsetFromTop: number;
  reactToValues?: any;
  reducerToUse?: any;
  gridArea?: any;
  children: any;
  defaultValues?: DefaultValues;
  useDefault?: any;
};

const StickyContainer = ({
  offsetFromTop,
  reactToValues = [],
  reducerToUse,
  gridArea,
  children,
  defaultValues = { height: 0 },
  useDefault = false
}: Props) => {
  const containerRef = useRef(null);
  const caddyRef = useRef(null);

  const [firstRender, setFirstRender] = useState(true);
  const [overallState, updateOverallState] = useState({
    height: null,
    top: null,
  });

  const takeMeasurements = () => {
    updateOverallState({
      height: caddyRef.current
        ? caddyRef.current.getBoundingClientRect().height
        : overallState.height,
      top: containerRef.current
        ? containerRef.current.getBoundingClientRect().top
        : overallState.top,
    });
  };

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      takeMeasurements();
    }
  });

  useEffect(() => {
    takeMeasurements();
    window.addEventListener("scroll", takeMeasurements);
    window.addEventListener("resize", takeMeasurements);

    return () => {
      window.removeEventListener("scroll", takeMeasurements);
      window.removeEventListener("resize", takeMeasurements);
    };
  }, [...reactToValues, offsetFromTop, reducerToUse]);

  const caddyPosition = useMemo(
    () => (overallState.top - offsetFromTop > 0 ? "absolute" : "fixed"),
    Object.values(overallState)
  );

  return (
    <div className={styles.Wrapper}
      ref={containerRef}
      style={{
        gridArea: gridArea || null,
        height: useDefault ? defaultValues.height :  overallState.height || defaultValues.height,
      }}
    >
      <div className={styles.Caddy}
        ref={caddyRef}
        style={{
          position: caddyPosition,
          top: caddyPosition === "fixed" ? `${offsetFromTop}px` : "0px",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default StickyContainer;
