// import ReactGA from "react-ga";

const debugMode =
  process.env.NEXT_PUBLIC_GA_ENABLE_DEBUG !== undefined ? process.env.NEXT_PUBLIC_GA_ENABLE_DEBUG === "TRUE" : false;

/**
 * Ensures any requirements for GA to work are properly initialised
 * * Only run on clientside
 * ! This is a fallback for UA
 */
export const init = () => {
  if (!window.NEXT_PUBLIC_GA_INITIALIZED) {
    // UA
    // ReactGA.initialize(process.env.NEXT_PUBLIC_GA_CODE, {
    //   debug: debugMode,
    // });

    window.NEXT_PUBLIC_GA_INITIALIZED = true;
  }
};

export default init;
