import { useDispatch } from "react-redux";
import { modalSet } from "_slices/view";
import styles from './headerUser.module.css';
import buttons from "components/_styled/buttons.module.css";

const UserLoading = () => {
  const dispatch = useDispatch();

  return (
    <span
      id="cr-user"
      className={styles.NavUser}
    >

      <button className={buttons.primaryButton} type="button" onClick={(e) => { dispatch(modalSet({ visible: true, type: "auth", view: "login" })) }} >Sign In</button>

    </span>
  )

};

export default UserLoading;
